import Vue from "vue";
import Vuetify from "vuetify";

// Translation provided by Vuetify (javascript)
import { ko } from "vuetify/src/locale";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    breakpoint: {
        thresholds: {
            xs: 576,
            sm: 768,
            md: 1024,
            lg: 1200,
        },
    },
    themes: {
        light: {
            primary: "#f7e707",
            secondary: "#000",
            accent: "#82B1FF",
            error: '#FF5252',
            info: "#2196F3",
            success: '#4CAF50',
            warning: '#FFC107',
            anchor: "inherit",
            "grey-1a": "#1a1a1a",
            "grey-38": "#383838",
            "grey-68": "#686868",
            "grey-c7": "#c7c7c7",
            "grey-cb": "#cbcbcb",
            "grey-da": "#dadada",
            "grey-ef": "#efefef",
            "grey-e": "#eee",
            "grey-e2": "#e2e2e2",
            "grey-7": "#777",
            "grey-f5": "#f5f5f5",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            // error: '#FF5252',
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function(theme) {
    return new Vuetify({
        lang: {
            locales: { ko },
            current: "ko",
        },
        theme: theme || basicTheme,
    });
}
