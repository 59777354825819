<template>
    <v-select v-model="$i18n.locale" :items="items" item-text="text" item-value="value" @input="input"></v-select>
</template>
<script>
import axios from "@/plugins/axios";

export default {
    data(){
        return {
            items: [
                { text: "KO", value: "ko" },
                { text: "EN", value: "en" },
                { text: "CN", value: "cn" }
            ]
        }
    },
    methods: {
        input(locale){
            axios.defaults.headers.common['Accept-Language'] = locale;
        }
    }
}
</script>