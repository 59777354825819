var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "v-btn--primary",
    attrs: {
      "to": _vm.to,
      "large": "",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.tit))]), _c('i', {
    staticClass: "icon icon-right-arrow ml-12 ml-lg-20"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }