import axios from "@/plugins/axios";

let url = "/api/v1/banners";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data){
        return axios.get(`${url}/${data.code}`).then(result => result.data);
    }
}
