var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "items": _vm.items,
      "item-text": "text",
      "item-value": "value"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.$i18n.locale,
      callback: function ($$v) {
        _vm.$set(_vm.$i18n, "locale", $$v);
      },
      expression: "$i18n.locale"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }