import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";

import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    // {
    //     path: "/main2",
    //     component: () => import("../pages/client/Main_.vue"),
    //     props: true,
    // },
    {
        path: "/solution/led",
        component: () => import("../pages/client/solution/LedSolutionList.vue"),
        props: true,
    },
    {
        path: "/solution/non-lighting",
        component: () => import("../pages/client/solution/LightingSolutionList.vue"),
        props: true,
    },
    {
        path: "/series",
        component: () => import("../pages/client/series/SeriesList.vue"),
        props: true,
    },
    {
        path: "/series/product",
        component: () => import("../pages/client/product/ProductList.vue"),
        props: true,
    },
    {
        path: "/series/product/:product",
        component: () => import("../pages/client/product/ProductView.vue"),
        props: true,
    },
    // 회사소개
    {
        path: "/about",
        component: () => import("../pages/client/sub/about.vue"),
        props: true,
    },
    // 납품사례
    {
        path: "/business",
        component: () => import("../pages/client/sub/business.vue"),
        props: true,
    },
    {
        path: "/business/:business",
        component: () => import("../pages/client/sub/delivery/delivery.vue"),
        props: true,
    },
    // 문의하기
    {
        path: "/inquiry",
        component: () => import("../pages/client/sub/inquiry.vue"),
        props: true,
    },
    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/privacy.vue"),
        props: true,
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 영문 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/en",
        component: () => import("../pages/client/en/Main.vue"),
    },
    // {
    //     path: "/en/main2",
    //     component: () => import("../pages/client/en/Main_.vue"),
    //     props: true,
    // },
    {
        path: "/en/solution/led",
        component: () => import("../pages/client/en/solution/LedSolutionList.vue"),
        props: true,
    },
    {
        path: "/en/solution/non-lighting",
        component: () => import("../pages/client/en/solution/LightingSolutionList.vue"),
        props: true,
    },
    {
        path: "/en/series",
        component: () => import("../pages/client/en/series/SeriesList.vue"),
        props: true,
    },
    {
        path: "/en/series/product",
        component: () => import("../pages/client/en/product/ProductList.vue"),
        props: true,
    },
    {
        path: "/en/series/product/:product",
        component: () => import("../pages/client/en/product/ProductView.vue"),
        props: true,
    },
    // 회사소개
    {
        path: "/en/about",
        component: () => import("../pages/client/en/sub/about.vue"),
        props: true,
    },
    // 납품사례
    {
        path: "/en/business",
        component: () => import("../pages/client/en/sub/business.vue"),
        props: true,
    },
    {
        path: "/en/business/:business",
        component: () => import("../pages/client/en/sub/delivery/delivery.vue"),
        props: true,
    },
    // 문의하기
    {
        path: "/en/inquiry",
        component: () => import("../pages/client/en/sub/inquiry.vue"),
        props: true,
    },
    {
        // 가이드
        path: "/en/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/Guide.vue"),
        props: true,
    },
    // {
    //     // 개인정보처리방침
    //     path: "/en/about/press",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/board/AuthorBoard.vue"),
    //     props: true,
    // },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 메인로고
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 메인로고
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 메인로고
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - smtp설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 한글 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
        // 관리자 - 시리즈
        path: "/console/solution",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/ProgramListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/deliveryCreate",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/DeliveryBoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/deliveryBoards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/DeliveryBoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/ko/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/ko/FormView.vue"),
    },
    {
        // 관리자 - 상품관리
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ko/ProductList.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 생성
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ko/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 view
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ko/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 카탈로그
        path: "/console/catalog",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/ko/CatalogView.vue"),
        props: true,
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 영문 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
        // 관리자 - 시리즈
        path: "/console/en/solution",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/en/ProgramListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/en/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/en/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/en/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/en/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/en/boards/deliveryCreate",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/en/DeliveryBoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/en/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/en/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/en/deliveryBoards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/en/DeliveryBoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/en/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/en/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/en/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/en/FormView.vue"),
    },
    {
        // 관리자 - 상품관리
        path: "/console/en/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/en/ProductList.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 생성
        path: "/console/en/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/en/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 view
        path: "/console/en/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/en/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 카탈로그
        path: "/console/en/catalog",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/en/CatalogView.vue"),
        props: true,
    },


    
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf("console")) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
