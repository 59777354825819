var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('h1', {
    staticClass: "header__logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("민아트프레임")])])], 1), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__gnb"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1), _c('div', {
    staticClass: "user-menu--moblie w-50"
  }, [_c('router-link', {
    staticClass: "d-flex align-center pa-8",
    attrs: {
      "to": "/en"
    }
  }, [_c('i', {
    staticClass: "icon icon-eng mr-10"
  }), _vm._v(" ENG ")])], 1)]), _c('div', {
    staticClass: "header__right d-none d-xl-flex"
  }, [_c('v-btn', {
    staticClass: "v-btn--primary mr-16",
    attrs: {
      "rounded": "",
      "outlined": "",
      "color": "secondary",
      "to": "/inquiry"
    }
  }, [_vm._v("문의하기")]), _c('div', {
    staticClass: "lang-wrap"
  }, [_vm._m(0), _c('ul', {
    staticClass: "lang"
  }, [_c('li', {
    staticClass: "on"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('i', {
    staticClass: "icon icon-kor mr-10"
  }), _vm._v(" KOR ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/en"
    }
  }, [_c('i', {
    staticClass: "icon icon-eng mr-10"
  }), _vm._v(" ENG ")])], 1)])])], 1)]), _vm._m(1), _vm._m(2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "lang-btn"
  }, [_c('i', {
    staticClass: "icon icon-global mr-8"
  }), _vm._v(" KOR ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hamburger-btn"
  }, [_c('span')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "depth2-colse-btn"
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-lg"
  })]);

}]

export { render, staticRenderFns }