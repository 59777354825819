<template>
    <v-btn :to="to" large color="primary" class="v-btn--primary">
        <span class="secondary--text">{{tit}}</span>
        <i class="icon icon-right-arrow ml-12 ml-lg-20"></i>
    </v-btn>
    <!-- <v-btn v-if="type == 'primary'" :to="to" large color="primary">
        <span class="color-black">{{tit}}</span>
        <i class="icon icon-right-arrow ml-12 ml-lg-20"></i>
    </v-btn> -->
</template>

<script>
export default {
    props : {
        tit : {type :String, default : ""},
        to : {type :String, default : ""},
        // type : {type :String, default : ""},
    },
}
</script>