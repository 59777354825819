import axios from "@/plugins/axios";

let url = "/api/console/setting";

export default {
    get() {
        return axios.get(url).then((result) => result.data);
    },
    put(data) {
        return axios.put(url, data).then((result) => result.data);
    },
    putKoSurveyURL(data) {
        if (data.surveyURL) {
            var formData = new FormData();
            formData.append("catalog", data.surveyURL);
            var headers = { "Content-Type": "multipart/form-data" };
            return axios.put(`${url}/koCatalog`, formData, { headers });
        }
    },
    putKoSurveyURL2(data) {
        if (data.surveyURL2) {
            var formData = new FormData();
            formData.append("catalog2", data.surveyURL2);
            var headers = { "Content-Type": "multipart/form-data" };
            return axios.put(`${url}/koCatalog2`, formData, { headers });
        }
    },
    putEnSurveyURL(data) {
        if (data.enSurveyURL) {
            var formData = new FormData();
            formData.append("enCatalog", data.enSurveyURL);
            var headers = { "Content-Type": "multipart/form-data" };
            return axios.put(`${url}/enCatalog`, formData, { headers });
        }
    },
    putEnSurveyURL2(data) {
        if (data.enSurveyURL2) {
            var formData = new FormData();
            formData.append("enCatalog2", data.enSurveyURL2);
            var headers = { "Content-Type": "multipart/form-data" };
            return axios.put(`${url}/enCatalog2`, formData, { headers });
        }
    },
    putSmtp(data) {
        return axios.put(url, data).then((result) => result.data);
    },
};
