<template>
    <v-row justify="center" align="center">
        <v-col cols="auto" class="line-height-1">
            <router-link to="/board/youtube" class="d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text">
                <i class="icon icon-youtube mr-4 mr-lg-8"></i><span>캐터옥션 유튜브</span>
            </router-link>
        </v-col>
        <v-col cols="auto" class="line-height-1">
            <router-link to="/board/report" class="d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text">
                <i class="icon icon-report mr-4 mr-lg-8"></i><span>캐터옥션 리포트</span>
            </router-link>
        </v-col>
        <v-col cols="auto" class="line-height-1">
            <router-link to="/board/forms" class="d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text">
                <i class="icon icon-forms mr-4 mr-lg-8"></i><span>Forms &amp; Archives</span>
            </router-link>
        </v-col>
        <v-col cols="auto" class="line-height-1" v-if="accessToken">
            <router-link to="/mypage/myinfo" class="d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text">
                <i class="icon icon-person mr-4 mr-lg-8"></i><span>마이페이지</span>
            </router-link>
        </v-col>
        <v-col cols="auto" class="line-height-1">
            <gnb-login-button></gnb-login-button>
        </v-col>
    </v-row>
</template>

<script>
import gnbLoginButton from "@/components/client/login/gnb-login-button.vue";

export default {
    components: {
        gnbLoginButton,
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        }
    }
};
</script>