var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'gnb' ? _c('div', {
    staticClass: "w-100 d-xl-flex justify-space-between"
  }, [_c('ul', {
    staticClass: "gnb gnb__left"
  }, [_vm._l(_vm.gnbs, function (item) {
    return [item.children ? _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_vm.isMobile ? _c('div', {
      class: _vm.className + '__link gnb__link--toggle'
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]) : _c('router-link', {
      class: _vm.className + '__link gnb__link--toggle',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('ul', {
      class: _vm.className + '__sub'
    }, [_vm.isDesktop ? _c('div', {
      class: _vm.className + '__sub-tit'
    }, [_c('div', {
      class: _vm.className + '__sub-tit__inner'
    }, [_c('router-link', {
      class: _vm.className + '__sub-tit-link',
      attrs: {
        "to": item.path
      }
    }, [_vm._v(_vm._s(item.title))])], 1)]) : _vm._e(), _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('a', {
        attrs: {
          "href": child.path
        }
      }, [_c('span', {
        staticClass: "w-100 d-block ellip"
      }, [_vm._v(_vm._s(child.title))])])]);
    }), _vm.isMobile ? _c('li', {
      staticClass: "gnb__sub-li--series-all"
    }, [_c('a', {
      class: _vm.className + '__series-all',
      attrs: {
        "href": item.path
      }
    }, [_vm._v("All Series")])]) : _vm._e()], 2)], 1) : _vm._e()];
  })], 2), _c('ul', {
    staticClass: "gnb gnb__right"
  }, [_vm._l(_vm.gnbs, function (item) {
    return [!item.children ? _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _vm._e()];
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }