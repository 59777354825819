var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', [_c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('v-img', {
    staticClass: "w-100 mr-lg-56 mb-20 mb-lg-0",
    attrs: {
      "src": "/images/ci.svg",
      "contain": "",
      "max-width": "146"
    }
  }), _c('div', [_c('p', {
    staticClass: "mb-lg-14"
  }, [_vm._v(" 회사명 : 민아트프레임"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" 주소 : 경기도 화성시 비봉면 양노로 103"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" 대표자 : 민은홍 ")]), _c('p', [_vm._v(" 사업등록번호 : 108-05-82640"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" 전화번호 : 031-465-9088"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" 팩스번호 : 031-465-9098"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" 이메일 : minart369@naver.com ")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }